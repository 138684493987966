.policyMainArea {
	padding-top: 2vw;
	padding-bottom: 2vw;
}

.policyMainArea h1 {
	font-size: max(2vw, 30px);
	font-weight: 600;
	text-align: center;
}

.policyMainArea h2 {
	font-size: max(1vw, 16px);
	font-weight: 600;
}

.policyMainArea p {
	font-size: max(1vw, 16px);
}