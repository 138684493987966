.loaderScreenArea {
	background-color: rgba(0,0,0,0.5);
	/* position: absolute; */
	position: fixed;
	top: 0;
	left: 0;
	z-index: 3;
	width: 100vw;
	height: 100vh;
}

.loaderContainer {
	margin-top: calc( 50vh - 50px);
	/* background-color: red; */
}