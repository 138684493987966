.benifits-outer {
  border: 0.195441px solid #121212;
  /* box-sizing: border-box; */
  border-radius: 4.88602px;
  max-width: 380px;
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  /* margin: 10px; */
  margin-top: 40px;
  justify-content: flex-start;
  background: #ffffff;
}

.benifits-outer p {
  font-size: 17px;
}

.image-benifits {
  object-fit: contain;
}
