.team-image {
  /* height: 80%; */
  width: 100%;
}

.team-description {
  background: #3b3b3f;
  text-align: center;
  padding: 10px;
  min-height: 130px;
  max-height: 130px;
}

.team-description h2 {
  color: #ffffff;
  font-size: 30px;
  font-weight: 700;
}

.team-description h3 {
  color: #ffffff;
  font-size: 18px;
  font-weight: 300;
}