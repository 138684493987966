.dc_onboarding_form {
	padding-top: 100px;
	padding-bottom: 100px;
	min-height: 100vh;
}

.onboarding_form {
	max-width: 500px;
	margin: auto;
	background-color: red;
	height: 500px;
}