.backgroundVideo {
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;
	object-fit: cover;
	-o-filter: blur(1px);
	filter: blur(1px);
	-webkit-filter: opacity(50%);
	filter: opacity(50%);
}

.DC_About_Section {
	height: 100vh;
	width: 100%;
	position: relative;
	z-index: -2;
	background-color: black;
}

.DC_about_header {
	/* background-color: yellow; */
	height: 300px;
	width: 100%;

}

.DC_About_Section h1 {
	text-align: center;
	font-size: 36px;
	font-weight: 500;
	/* background-color: red; */
	margin-top: 200px;
	color: white;
}

.DC_About_Section .rupinDesignClubHeading {
	font-size: 80px;
	text-transform: uppercase;
	letter-spacing: 5px;
}

.DC_About_Section p {
	font-size: 20px;
	text-align: center;
	color: gold;
}

.featuresSection {
	/* background-color: yellow; */
	padding: 0;
	width: 100%;
	padding: 0;
}

.featuresSection .container-fluid {
	padding: 0;
}

.featuresSection img {
	height: 100%;
	width: 100%;
	object-fit: cover;
}

.DC_Form_Section {
	min-height: 500px;
	padding-bottom: 100px;
}

.DC_Form_Section h1 {
	margin-top: 40px;
	text-align: center;
	font-size: 20px;
	color: black;
}

.DC_Form_Section .formArea {
	width: 100%;
	padding-top: 20px;
}

.DC_Form_Section .formArea .row {
	max-width: 500px;
	margin: auto;
}

.DC_Form_Section .formArea p {
	text-align: left;
	font-size: 14px;
	font-weight: 400;
	margin-top: 10px;
}

.DC_Form_Section .formArea input {
	text-align: left;
	font-size: 18px;
	height: 40px;
	width: 100%;
	border-radius: 5px;
	/* border-color: gold; */
	border: solid 1px gold;
	padding-left: 10px;
}

.DC_Form_Section .formArea .phoneInputBox {
	display: flex;
	
}

.DC_Form_Section .formArea .phoneExtension {
	line-height: 40px;
	font-size: 18px;
	padding-right: 10px;
}

.DC_Form_Section .formArea button {
	width: 100%;
	margin-top: 20px;
	height: 50px;
	background-color: gold;
	border: solid 1px black;
	border-radius: 5px;
	font-size: 18px;
}

.contactFormStatusText {
	color: rgb(207, 91, 14);
}


@media only screen and (max-width: 768px) {
	.DC_About_Section {
		height: 400px;
	}

	.DC_About_Section h1 {
		font-size: 20px;
		font-weight: 500;
		/* background-color: red; */
		margin-top: 150px;
		color: white;
	}

	.DC_About_Section .rupinDesignClubHeading {
		font-size: 45px;
		letter-spacing: 2px;
	}
}