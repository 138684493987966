.productDescModal {
	z-index: 5;
	position: fixed;
	top: 0;
	left: 0;
	min-height: 100vh;
	height: 100%;
	width: 100vw;
	background-color: rgba(0,0,0,0.5);
	padding: 50px;
	overflow-y: auto;
}

.viewModal {
	display: block;
}

.hideModal {
	display: none;
}

.modalContainer {
	background-color: white;
	height: 100%;
	width: 100%;
	position: relative;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	overflow-y: scroll;
}

.closeButton {
	position: absolute;
	right: 10px;
	top: 10px;
	height: 30px;
	width: 30px;
	cursor: pointer;
	z-index: 6;
	/* background-color: red; */
}

.closeButton img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.productDescModal .container, .productDescModal .row {
	height: 100%;
}

.productGalleryContainer {
	/* background-color: red; */
	height: 100%;
	width: 100%;
}

.productGalleryContainer .rightArrow, .productGalleryContainer .leftArrow {
	position: absolute;
	z-index: 6;
	cursor: pointer;
	top: calc(50% - 30px);
	height: 60px;
	width: 60px;
}

.productGalleryContainer .leftArrow {
	left: 0;
}

.productGalleryContainer .rightArrow {
	right: 0;
}

.productGalleryContainer img {
	height: 100%;
	width: 100%;
	object-fit: cover;
}

.productDescContainer {
	/* background-color: green; */
	padding: 20px;
	height: 100%;
}

.productDescContainer h1 {
	font-size: 22px;
	font-weight: 600;
}

.productDescContainer p {
	font-size: 18px;
	font-weight: 400;
}

.productSpecificationArea {
	/* background-color: yellow; */
}

.specificationList {
	font-size: 16px;
	font-weight: 400;
}

.productDescContainer .productPrice {
	/* font-weight: 800; */
	display: flex;
	line-height: 30px;
	font-size: 16px;
}

.productDescContainer .productPrice span {
	display: flex;
	font-size: 20px;
	color: gold;
	
}

.productDescContainer .productPrice h1 {
	font-size: 25px;
	font-weight: 600;
	margin-left: 5px;
	margin-right: 5px;
	color: black;
}

.buyNowLinkContainer {
	/* background-color: red; */
	padding: 10px;
	display: flex;
}

.buyNowLinkContainer .buyNowButton {
	height: 50px;
	width: 150px;
	margin-right: 10px;
	border-radius: 5px;
	border: solid 2px gold;
	text-align: center;
	font-size: 18px;
	padding: 5px;
	cursor: pointer;
}

.buyNowLinkContainer .buyNowButton img {
	height: 100%;
	width: 100%;
	object-fit: contain;
	/* background-color: red; */
}

.buyNowLinkContainer .buyNowAmazon {
	/* background-color: green; */
	padding: 10px;
	
}

.buyNowLinkContainer .buyNowFlipkart {
	/* background-color: blue; */
}

.contactUsButton {
	padding-top: 1vw;
}

.contactUsButton a {
	background-color: gold;
	border: none;
	border-radius: 5px;
	font-size: max(1vw, 16px);
	padding-left: 2vw;
	padding-right: 2vw;
	width: 100%;
	padding-top: 1vw;
	padding-bottom: 1vw;
	cursor: pointer;
	text-decoration: none;
	color: black;
}

.contactUsButton a:hover {
	background-color: black;
	color: gold;
}


@media only screen and (max-width: 768px) {
	.productGalleryContainer {
		/* background-color: red; */
		height: 50vh;
	}
	.productDescModal {
		padding: 30px;
		padding-left: 10px;
		padding-right: 10px;	
	}
	.productGalleryContainer .rightArrow, .productGalleryContainer .leftArrow {
		height: 40px;
		width: 40px;
		top: calc(50% - 20px);
	}
}