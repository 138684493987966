.products-cards-section {
	/* background-color: red; */
	padding-bottom: 100px;
	padding-top: 50px;
}

.product_card {
	/* background-color: green; */
	height: 400px;
	width: 100%;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	cursor: pointer;
}

.product_card .cardThumbArea {
	background-color: rgba(255,215,0,0.5);
	background-color: white;
	height: 340px;
}

.product_card .cardThumbArea img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.product_card .cardDescArea {
	background-color: gold;
	height: 60px;
	width: 100%;
	text-align: center;
	padding-top: 10px;
	padding-bottom: 10px;
	overflow: hidden;
	padding-left: 10px;
	padding-right: 10px;
	color: black;

}

.product_card .cardDescArea h1 {
	font-size: 18px;
	font-weight: 400;
	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.product_card .cardDescArea p {
	font-size: 14px;
	font-weight: 300;
	white-space: nowrap;
	text-align: justify;
	text-justify: inter-word;
  overflow: hidden;
  text-overflow: ellipsis;
}