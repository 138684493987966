.connect-form {
  padding: 50px 0;
}

.connect-form input {
  width: 100%;
  font-size: 1.5rem;
  padding: 0.4rem;
  border: 1px solid black;
}

.connect-form textarea {
  width: 100%;
  font-size: 1.5rem;
  padding: 0.4rem;
  border: 1px solid black;
}

.connect-form h1 {
  font-weight: 900;
  font-size: 40px;
}

.connect-form h3 {
  color: rgba(26, 26, 26, 0.6);
}

.connect-form h4 {
  color: #1a1a1a;
  font-weight: 700;
}

.connect-form-btn {
  background-color: #1a1a1a;
  color: #ffffff;
  padding: 10px;
  font-size: 12px;
}

.connect-form-btn:hover {
  background-color: #ffffff;
  background-color: #1a1a1a;
  border: 1px solid #1a1a1a;
}

.contactUsStatusText {
	font-size: max(1vw, 16px);
	color: rgb(207, 91, 14);
}