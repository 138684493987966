.footer {
	/* width: 100vw; */
  margin-top: auto;
  background-color: #000000;
  color: #ffffff;
  padding: 50px 50px;
  font-weight: 600;
}

.footer p {
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
}

.footer h3 {
  font-size: max(1.5vw, 18px);
	font-weight: 300;
}

.footer_options_list a {
  color: white;
  text-decoration: none;
  font-size: max(1vw, 14px);
	font-weight: 300;
}

.footer_options_list a:hover {
  color: gold;
  text-decoration: none;
}

.footer_social_media {
  text-align: right;
}

.footer_social_media img {
  height: 30px;
  width: 30px;
  margin-left: 10px;
}