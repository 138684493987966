.homeTopBackgroundImage {
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
}

.homeTopBackgroundImage img {
	object-fit: cover;
	width: 100%;
	height: 100%;
}

.home-image img {
  /* width: 90%; */
  /* height: 70%; */
  height: 100vh;
  width: 45vw;
  object-fit: cover;
}

.home-part-one {
  /* background: url("../../../public/assets/images/home.png"); */
  /* background-repeat: no-repeat; */
}

.home-text {
  /* margin: ; */
}

.home-text h1 {
  color: #ffffff;
  font-size: 65px;
}

.contact-btn {
  color: #000000;
  background-color: #ffffff;
  padding: 10px;
  width: 150px;
  transition: 0.3s all ease-out;
  font-size: 20px;
}

.contact-btn:hover {
  background-color: #000000;
  color: #ffffff;
}

.home-text {
  margin: auto;
}

.home-btn {
  background: linear-gradient(89.07deg, #ffb744 2.06%, #d4af37 117.36%);
  border-radius: 4px;
  color: black;
  outline: none;
  border: none;
  padding: 10px 25px;
  font-size: 15px;
}

.box-home {
  color: #000000;
  background: #fcfcfc;
  box-shadow: 0px 3.63172px 6.35551px 0.90793px rgba(0, 0, 0, 0.12);
  border-radius: 3.63172px;
  transition: 0.3s all ease-out;
  cursor: pointer;
  padding: 3rem 1.5rem;
  /* margin-bottom: -20px; */
  height: 100%;
}

.box-home h2 {
  font-size: 22px;
}

.box-home p {
  font-size: 16px;
}

.box-home:hover {
  /* background: linear-gradient(89.07deg, #d1a96a 2.06%, #d4af37 117.36%); */
  /* background-color: gold; */
  background-color: rgba(0, 0, 0, 0.4);
  color: #ffffff;
}

.bottom-margin {
  margin-bottom: -100px;
}

.home-text-para {
  background: rgba(0, 0, 0, 0.3);
  font-size: 18px;
  color: #ffffff;
  padding: 4px;
}

.home-part-two {
  background: #f7f9fc;
  margin-top: 100px;
}

.text-area-home {
  padding: 30px;
}

.home-part-two h3 {
  /* text-align: center; */
  font-size: 45px;
  font-weight: 300;
  padding: 50px 0;
}

.home-part-three h3 {
  /* padding: 0 50px; */
  font-size: 50px;
}

.home-part-three {
  padding: 0 50px;
}

.benifits-section {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.alexa-img {
  float: left;
  width: 40%;
  margin-right: 20px;
}

.home-part-four {
  padding: 100px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* flex-direction: column */
}

.span-text {
  background: #d4af37;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* color: linear-gradient(, ); */
  font-weight: 900;
}

.benifits-section h2 {
  color: #d4af37;
  font-size: x-large;
  font-weight: bold;
}

.our-products {
  margin-top: auto;
  background-color: #000000;
  color: #ffffff;
  padding: 50px 50px;
  font-weight: 600;
}

.products-column {
  background: #657a9d;
  padding: 50px;
  cursor: pointer;
  /* height: 85%;
  width: 85%; */
}

.products-column-2 {
  background: #7487a7;
  padding: 20px;
  cursor: pointer;
  text-align: center;
  height: 100%;
}

.products-column-2 p {
  font-size: 16px;
  font-weight: 400;
}

.soul-image {
  width: 100%;
}

.sense-image {
  width: 400px;
  height: 250px;
  /* background-color: red; */
  object-fit: cover;
}

.our-products h1 {
  margin-left: 50px;
  font-size: 40px;
}

.our-products h2 {
  font-size: 40px;
}

.margin-top-6 {
  margin-top: 3rem;
}

.home-part-four h1 {
  font-size: 49px;
  font-weight: 400;
}

.home-part-four h3 {
  font-size: 30px;
  font-weight: 300;
}

.mobile-application {
  padding-top: 30px;
}

.mobile-application h1 {
  font-size: 40px;
  color: #134375;
  font-weight: 700;
  padding-bottom: 30px;
}

.mobile-application h2 {
  font-size: 30px;
  font-weight: 700;
  color: #d4af37;
}

.mobile-application h3 {
  color: rgba(26, 26, 26, 0.82);
  font-size: 25px;
}

.mobile-application img {
  width: 100%;
}

.how-we-work {
  padding: 100px 0;
}

.how-we-work h1 {
  font-size: 40px;
  color: #1a1a1a;
  font-weight: 700;
  padding-bottom: 30px;
}

.how-we-work img {
  width: 100%;
}

.how-we-work h2 {
  font-size: 30px;
  font-weight: 700;
}

.how-we-work h3 {
  font-size: 26px;
  font-weight: 300;
}



@media (max-width: 768px) {
  .text-area-home {
    padding: 10px;
  }
  .home-part-one {
    flex-direction: column;
    padding: 0;
  }
  .home-image img {
    width: 100vw;
    height: 60vh;
    object-fit: cover;
  }
  .logo-image {
    padding: 10px;
  }
  .home-part-two h3 {
    font-size: 30px;
    padding: 60px 20px;
  }
  .home-part-three h3 {
    font-size: 30px;
  }
  .home-part-three {
    padding: 0 20px;
  }
  .alexa-img {
    width: 100%;
    margin-bottom: 20px;
  }
  .home-part-four {
    padding: 20px 20px;
    display: block;
    /* flex-direction: column */
  }
  .our-products {
    padding: 20px;
  }
  .our-products h1 {
    margin-left: 0;
  }
}

.led_strip_product_column {
	/* background-color: white; */
	/* color: black; */
}

.led_strip_color_section {
	width: 100%;
	/* background-color: red; */
}

.led_color_area {
	display: flex;
}

.led_color_box {
	border: solid 1px black;
	height: 50px;
	width: 50px;
	margin: 5px;
}

.led_color_area .warm_white {
	background-color: #ffd90e;
}

.led_color_area .cool_white {
	background-color:  #FFFFFF;
}

.led_color_area .blue {
	background-color: blue;
}

.led_color_area .green {
	background-color: green;
}

.learnMoreLink {
	font-size: max(1vw, 16px);
	text-align: center;
	width: 100%;
	color: white;
  text-decoration: none;
	cursor: pointer;
	margin-top: 1vw;
}

.learnMoreLink:hover {
	color: gold;
  text-decoration: none;
}