
.menu-header {
	background: #1a1a1a;
	padding: 10px 20px;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 1;
  }
  
  .header_left_side {
	display: flex;
  }
  
  .header_logo_area {
	width: 80px;
	/* background-color: red; */
  }
  
  .header_contact_info_area {
	/* background-color: green; */
	width: 100%;
  }
  
  .header_contact_info_area a {
	/* background-color: yellow; */
	height: 30px;
	/* border: solid black 1px; */
	position: relative;
	color: white;
	text-decoration: none;
	padding: 0;
  }
  
  .header_contact_info_area a:hover {
	color: gold;
  }
  
  .header_contact_info_area a > img {
	height: 30px;
	padding: 6px;
	position: absolute;
	/* bottom: 0; */
  }
  
  .header_contact_info_area a > h4 {
	left: 30px;
	position: absolute;
	line-height: 24px;
	bottom: 0;
	height: 30px;
	font-size: 14px;
	/* background-color: tomato; */
  }
  
  .menu-items {
	color: #ffffff;
	display: flex;
	justify-content: space-between;
	cursor: pointer;
	align-items: center;
	flex-wrap: wrap;
  }
  
  .menu-items h2 {
	font-size: 16px;
  }
  
  .menu-items h2 > a  {
	font-size: 16px;
	color: white;
	text-decoration: none;
  }
  
  .menu-items h2 > a:hover {
	color: gold;
  }
  
  .logo-image {
	padding-top: 20px;
	display: flex;
	/* justify-content: space-between; */
	flex-direction: column;
	max-width: 45%;
  }
  
  .logo-image h2 {
	font-size: 65px;
  }
  
  .logo-image p {
	font-size: 20px;
  }

  .hamburger_menu {
	  position: absolute;
	  height: 60px;
	  width: 60px;
	  right: 10px;
	  top: 10px;
	  background: none;
	  border: none;
	  outline: none;
  }

  .side_menu_area {
	  background-color: rgba( 0,0,0,0.9);
	  width: 60vw;
	  height: 100vh;
	  position: fixed;
	  right: 0;
	  top: 0;
	  padding-top: 80px;
  }

  .side_menu_area .close_side_menu {
	  position: absolute;
	  height: 60px;
	  width: 60px;
	  right: 10px;
	  top: 10px;
	  background: none;
	  border: none;
	  outline: none;
  }

  .side_menu_area a {
	font-size: 18px;
	color: white;
	text-decoration: none;
	padding-left: 30px;
	/* background: yellow; */
  }

  .side_menu_area a:hover {
	color: gold;
  }

  .show_side_menu {
	  display: block;
  }

  .hide_side_menu {
	  display: none;
  }

  @media only screen and (max-width: 768px) {
	/* For tablets: */
	.header_menu_right_side {
		display: none;
	}
	
  }