.termsAndConditionsMainArea {
	padding-top: 2vw;
	padding-bottom: 2vw;
}

.termsAndConditionsMainArea h1 {
	font-size: max(2vw, 30px);
	font-weight: 600;
	text-align: center;
}

.termsAndConditionsMainArea h2 {
	font-size: max(1vw, 16px);
	font-weight: 600;
}

.termsAndConditionsMainArea p {
	font-size: max(1vw, 16px);
}

.termsAndConditionsMainArea p, li {
	font-size: max(1vw, 16px);
}